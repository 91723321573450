'use strict'

import React, {useState} from "react";
import AWS from 'aws-sdk';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button} from "react-bootstrap";


//https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/getting-started-react-native.html

const App = () => {
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [fileName, setFileName] = useState('')
    const [keyName, setKeyName] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [httpStatusCode, setHttpStatusCode] = useState(0);
    const [etag, setETag] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const region = 'us-east-1';
    const identityPoolId = 'us-east-1:5d0e1dcf-b6a9-415d-aeae-80d67d34a8a8';
    const bucketName = 'alc-atlas-zip-uploads'

    const handleSelectFile = (e) => {
        const file = e.target.files[0];
        const fileName = file.name;
        console.log(`event=handleSelectFile=${fileName}`);

        if (file && fileName.length > 0) {
            console.log("event=updating state");
            setSelectedFile(file);
            setFileName(fileName);
            setIsSelected(true);
        }
    }

    const uploadFileToS3 = async () => {
        // hides the Submit button once users click submit after selecting a file
        setIsSubmitted(true);

        console.log(`event=PutObjectCommand uploading file=${selectedFile.name}`)
        console.log(selectedFile);
        try {
            AWS.config.update({
                credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: identityPoolId}),
                region: region
            });

            const s3Client = new AWS.S3({params: {Bucket: bucketName}});
            const params = {
                Body: selectedFile,
                Key: `uploads/${fileName}`,
                ContentType: selectedFile.type,
                // ContentDisposition: 'attachment' // if you want to enable download file via public url
            }

            /* turn off the timeout (Optional) */
            AWS.config.httpOptions.timeout = 0

            //uploading file to s3 bucket:
            s3Client.upload(params).on('httpUploadProgress', (progress) => {
                const {loaded, total} = progress;
                const uploaded = Math.floor((loaded / total) * 100);
                setPercentage(uploaded)
                console.log(uploaded);
            }).send((err, data) => {
                if (err) {
                    console.error("event=Error Occurred while uploading file s3 bucket:", err);
                    return;
                }
                console.log("event=Success");
                const {Location, ETag, Key} = data
                setHttpStatusCode(200);
                setKeyName(Key)
                setETag(ETag.replaceAll('"', ""));
            })

        } catch (error) {
            console.error("error occurred while uploading file to s3 bucket", error)
        }
    }

    return (
        <Container style={{paddingTop: '60px'}}>
            <Row>
                <Row>
                   <Col xs={6}> <h3> Beta Version 1.0 </h3>
                       {/*<h5> Current Git Branch: aws_s3_lib</h5>*/}
                       <hr/>
                   </Col>
                </Row>
                <Row style={{paddingTop:'30px'}}>
                    <input type="file" onChange={handleSelectFile}/> <br/>
                </Row>
                <Col>
                    {
                        isSelected && selectedFile ?
                            <Row>
                                <ul style={{listStyle: "none"}}>
                                    <li><strong>File Name:</strong> {selectedFile.name}</li>
                                    <li><strong>Key:</strong>{`uploads/${selectedFile.name}`}</li>
                                    <li><strong>File Type:</strong> {selectedFile.type}</li>
                                    <li><strong>Size:</strong> {selectedFile.size}</li>
                                </ul>

                                {/*if successfully uploaded / status is (200 OK) then hide the upload button*/}
                                <Col style={{paddingTop: '10px'}}>
                                    {
                                        !isSubmitted ?
                                            <Button variant="primary" size="lg" onClick={uploadFileToS3}>Submit</Button>
                                            : percentage === 100 ? // if file uploaded to s3 display add another file button (refresh page)
                                                <Button variant="primary" size="lg"
                                                        onClick={() => window.location.reload()}>Add
                                                    another file</Button> : null
                                    }
                                </Col>
                            </Row>
                            :
                            null
                    }
                </Col>

                <Col>
                    {selectedFile && fileName ?
                        <Row >
                            <h5>Progress: {`${percentage}%`}</h5>
                            {/*Progress bar*/}
                            <Row>
                                <div id="myProgress"
                                     style={{width: `90%`, backgroundColor: 'lightgray', borderRadius: '25px'}}>
                                    <div id="myBar" style={{
                                        width: `${percentage}%`,
                                        height: '30px',
                                        backgroundColor: 'red',
                                        borderRadius: '20px'
                                    }}></div>
                                </div>
                            </Row>

                            <Row>
                                <ul style={{listStyle: "none"}}>

                                    <li><strong> HttpStatusCode:</strong> {httpStatusCode ? `${httpStatusCode} OK` : ''}
                                    </li>
                                    <li><strong> File Location: </strong> {keyName}</li>
                                    <li><strong> ETag:</strong> {etag} </li>
                                </ul>
                            </Row>
                        </Row>
                        :
                        null
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default App;